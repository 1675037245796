.pageItem {
  position: relative;
  margin-bottom: 20px;
  cursor: pointer;
}

.pageContent {
  margin-top: 16px;
  margin-left: 16px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
}

.pageTitleContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction:column;
  font-family: "inknut_antiquablack";
  margin-left: 20px;
}

.pageTitleContainer p {
  margin: 0;
  line-height: 24px;
  text-transform: capitalize;
  font-size: 16px;
}
.btn {
  font-family: "Newt Serif Regular";
  font-size: 15px;
  font-weight: 400;
  margin-top: 8px;
}
.btn:hover {
  font-weight: 600;
  text-decoration: underline;
}
.pageName {
  display: inline-block;
  font-size: 18px;
  font-weight: 700;
  color: black;
}

p.productType {
  margin: -12px 0 0 58px;
}

.PageImage {
  border-radius: 50%;
  position: relative;
  height: inherit;
}
.img {
  position: absolute;
  bottom: 5%;
  left: -10%;
  height: auto;
  width: 120%;
}
