.title {
  margin: 3rem 4rem;
}
.content {
  width: 100vw;
  text-align: center;
}
.checkOutPage_topClass {
  position: relative;
  justify-content: center;
  margin-top: 3%;
  padding: 0 0 5% 0;
  height: 100%;
}
.flexDiv {
  display: flex;
  justify-content: space-around;
  width:100%
}

.contentContainer {
  width: 55%;
  margin-left: 14px;
}
.img1 {
  width: 100%;
  height: 150px;
  background-color: black;
}
.contentContainer div div:nth-of-type(2) img {
  width: 100%!important;
  max-height:300px!important;
  object-fit: cover!important;
  object-position: center center!important;
}
.contentContainer div div:nth-of-type(3) img {
  width:50% !important;
}
.mainContent p, .mainContent li {
line-height: 1.5em;
}
.mainContent a {
  color: rgb(76, 139, 88);
  font-weight: bold;
}
.heading1 {
  font-size: 32px;
  font-weight: bold;
}
.description {
  color: #878f91;
}
.sidebarContainer {
  width:30%;

}
.img2 {
  width: 70%;
  height: 300px;
  background-color: black;
}

.heading2 {
  font-size: 32px;
  font-weight: bold;
}
.readMore {
  font-size: 32px;
  margin-bottom: 0.5rem;
  margin-top: 30px;
  font-weight: bold;
}
.onlinePages {
  width: 100%;
  margin-top: 20px;
  border-radius: 5px;
  border: 1.5px solid hsl(175, 41%, 18%);
  background-color: white;
}

@media screen and (max-width: 900px) {
  .checkOutPage_topClass {
    margin-top: 3rem;
  }
  .flexDiv {
    flex-direction: column;
    justify-content: center;
  }
  .contentContainer, .onlinePages {
    width: 88%;
  }
  .img2 {
    width: 100%;
  }
  .sidebarContainer {
    width: 100%;
    margin-left: 14px;
    margin-top: 50px;
  }
  .readMore {
    font-size: 24px;
  }
  .heading1 {
    font-size: 24px;
  }
  .heading2 {
    font-size: 24px;
    margin-bottom: 0px;
  }
}

