.cardContainer {
  min-width: 250px;
  width: 250px;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border: 2px solid rgba(76, 139, 88, 0.25);
  border-radius: 50%;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}
.cardHeading {
  font-weight: 200;
  white-space: nowrap;
  font-size: 1.5em;
  font-family: "Newt Serif Demi Regular";
}
.cardImg {
  width: 120% !important;
}
.cardImg2 {
  width: 100% !important;
}
@media (min-width: 769px) {
  .cardContainer {
    margin-bottom: 0;
    min-width: none;
  }
  .cardHeading {
    font-size: 2em;
  }
}
