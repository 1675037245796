.cartModalContainer {
  position: absolute;
  right: 0;
  width: 500px;
  top: 60px;
  z-index: 90000;
}

.cartFooter {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  font-size: 12px;
}

.empty {
  text-align: center;
  margin-top: 0;
}

@media only screen and (max-width: 760px) {
  .cartModalContainer {
    right: 1%;
    width: 98%;
    top: 68px;
  }
  .cartFooter {
    flex-direction: column;
  }
  .cartFooter button {
    font-size: 14px;
    margin-top: 10px;
  }
}
