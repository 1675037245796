.CartItem {
  margin: 30px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 200;
  color: #b29cbc;
}

.productContent {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;  
  z-index: 2000;
  flex-wrap: wrap;
}
.productNameWrapper{
  display: flex;
  flex-direction: row;
  justify-content: flex-start; 
  line-height: 20px;
}

.productTitleContainer {
  font-family: "inknut_antiquablack";
  margin-top: 10px;
  text-decoration: none;
}

.productTitleContainer p {
  margin: 0;
  line-height: 24px;
  text-transform: capitalize;
  font-size: 16px;
}

.productName {
  font-size: 16px;
  font-weight: 600;
  position: relative;
  color: #1b4141;
}

p.productType {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  margin-left: 18px;
  color: #1b4141;
}

.productPrice {
  display: inline-block;
  margin-left: 4px;
  font-size: 12px;
}
.productVariant {
  display: inline-block;
  margin-left: 4px;
  font-size: 10px;
}

.cartCountPosition {
  right: -20px;
  top: -5px;
  position: absolute;
}

.incrementContainer {
  display: flex;
  flex-direction: column;
  width: 25px;
  margin-right:5px;
}

.removeButton {
  font-family: "Newt Serif Regular";
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  margin-left: 100px;
  margin-top: -20px;
  width: 50%;
  color: rgb(106, 48, 22);
}
.removeButton:hover{
  text-decoration: underline;
  font-weight: 700;
}

@media only screen and (max-width: 760px) {
.productContent {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;  
  min-width:80%;
}
.cartCountPosition {
  margin-right: 5px;
}
.productName {
  font-size: 14px;
}

p.productType {
  font-size: 12px;
  margin-left: 10px;
}
.removeButton {
  margin-left: 10px;
}
}
