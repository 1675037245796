header {
  max-width: 100%;
  margin: 0 auto;
  position: fixed;
  min-height: 100px;
  width: 100%;
  z-index: 10;
}
header.wide {
  max-width: 95%;
  position: relative;
}

.cart {
  position: absolute;
  top: 20px;
  right: 10px;

}
.cart.landing {
  color: #4c8b58;
}
.home {
  display: flex;
  text-decoration: none;
}
.companyName {
  margin-left: 7px;
  padding: 6px;
  font-size: 16px;
  border-radius: 3px;
  font-weight: 700;
}
.subName{
  font-weight: 500;
}
.companyName span:first-child {
  font-size: 20px;
  margin: 0 6px 0 0;
  font-family: "Newt Serif Demi Regular";
}
.cartCountPosition {
  right: 0;
  bottom: 0;
  position: absolute;
}
.brandingWrap {
  position: relative;
}
.brandingWrap svg {
  width: 250px;
  opacity: 0.3;
  top: 0px;
  right: -25px;
  position: absolute;
  color: rgba(178, 156, 188, 0.8);
  
}
.quote {
  position: absolute;
  right: -172px;
  bottom: -470px;
  transform: rotate(270deg);
  font-size: 22px;
  font-weight: 700;
  z-index: -20;
  color: rgba(178, 156, 188, 0.5);
  font-family: "Newt Serif Demi Regular";
}

@media only screen and (min-width: 761px) and (max-width: 1440px) {
 header {
    left: 0;
  }
  header.wide {
    left: 0;
  }
}


@media only screen and (max-width: 760px) {
  header {
    left: 0;
  }
  header.wide {
    left: 0;
  }
  .companyName {
    margin-left: 12px;
    padding: 10px 40px 10px 10px;
    font-size: 16px;
  }
  .companyName span:first-child {
    font-size: 20px;
    margin: 0 12px 0 0;
  }
  .subName{
    display: block;
    top: 32px;
  }
  .cart {
    right: 14px;
    top: 32px;
  }
  .brandingWrap svg {
    width: 150px;
    top: -20px;
    right: -5px;
    position: absolute;
  }
  .quote {
    position: absolute;
    right: -172px;
    bottom: -350px;
  }
}