.ProductImage {
    border-radius: 50%;
    position: relative;
    height: 80px;
    min-width: 80px;
}
.img {
    position: absolute;
    bottom: 5%;
    left: -10%;
    height: auto;
    width: 120%;
}