.productDiv {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  padding: 0 5rem;
  width: 100%;
}
.ProductImage {
  border-radius: 50%;
  width: 300px;
  position: relative;
  height: 300px;
  z-index: 200;
}
.img {
  height: auto;
  width: 100%;
  position: absolute;
  top: 26%;
}
.title {
  font-size: 86px;
  font-family: "Newt Serif Bold Regular";
  margin: 0;
  position: absolute;
  left: 15%;
  z-index: 222;
  letter-spacing: -4px;
}
.description {
  width: 100%;
  max-width: 700px;
  font-size: 20px;
  margin: 1rem 1rem 1rem 5rem;
  font-family: "Newt Serif Demi Regular";
}
.type {
  margin: 6rem 0rem 2rem 4.5rem;
  font-size: 52px;
  font-family: "Newt Serif Bold Regular";
  color: rgba(198, 83, 198, 0.664);
}

.dollar {
  font-size: 20px;
}
.dollarAmount {
  font-size: 24px;
}
.productDetail {
  padding-top: 20px;
  width: 100%;
  height: 100%;
  z-index: 222;
}
.HeaderLogo {
  width: 200px;
  margin-top: 40px;
  margin-right: 60px;
}

.Checkout_portion {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: flex-start;
  z-index: 222;
}
.checkoutButton {
  color: hsl(185deg 13% 63%);
  font-size: 1.5vw !important;
  padding: 16px;
  background-color: hsl(175deg 41% 18%);
  border-radius: 5px;
  position: relative;
  font-family: "Newt Serif Demi Regular";
  cursor: pointer;
  display: flex;
  align-items: center;
}
.checkoutButton svg {
  fill: currentColor;
  margin-right: 16px;
}
.checkoutButton[data-in-cart="true"] {
  cursor: default;
}
.image2 {
  width: 125px;
  margin-left: 50px;
}
.incrementContainer {
  display: flex;
  flex-direction: column;
  margin-left: 8px;
}
.plusIcon {
  cursor: pointer;
}
.negativeIcon {
  margin-top: 7px;
  cursor: pointer;
}

.quote {
  position: absolute;
  top: 44%;
  color: hsl(34deg 64% 71%);
  transform: rotate(270deg);
  font-size: 19px;
  font-weight: bold;
  width: 320px;
  font-family: "Newt Serif Demi Regular";
}
.cartCountPosition {
  right: -4px;
  top: -4px;
  position: absolute;
  color: black;
}
.FooterLogo {
  width: 100%;
  margin-top: 40px;
  margin-right: 60px;
  position: absolute;
  bottom: -7%;
  left: 0;
}

.variantsDiv {
  display: flex;
  justify-content: flex-start;
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  margin-right: 50px;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #1b413e;
  border-radius: 100%;
  background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #1b413e;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

@media only screen and (min-width: 761px) and (max-width: 900px) {
  .image2 {
    width: 150px;
  }
  .productDiv {
    justify-content: space-around;
    flex-direction: column;
    padding: 0;
  }
  .title {
    left: 10%;
    top: 20%;
    font-size: 58px;
    width: 70%;
    z-index: 222;
    letter-spacing: -2px;
  }

  .type {
    margin: 1rem 0rem 1rem 2rem;
    position: absolute;
    left: 25%;
    top: 25%;
  }
  .description {
    position: static;
    margin: 10px 50px;
  }

  .ProductImage {
    border-radius: 50%;
    width: 200px;
    position: relative;
    height: 200px;
  }
  .productDetail {
    width: 90%;
  }
  .quote {
    position: absolute;
    top: 40%;
    left: 60%;
    font-size: 19px;
    width: 400px;
  }

  .Checkout_portion {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 0%;
  }

  .HeaderLogo {
    width: 120px;
    margin-top: 0;
    margin-right: 0;
    position: absolute;
    right: 15%;
    top: 5%;
  }
}

@media only screen and (max-width: 760px) {
  .title {
    position: static;
    font-size: 36px;
    width: 100%;
    letter-spacing: -2px;
  }

  .type {
    margin: 0rem 0rem 1rem 0rem;
    position: static;
    font-size: 28px;
  }
  .description {
    position: static;
    margin: 10px 0px;
  }
  .productDiv {
    justify-content: space-around;
    flex-direction: column;
    padding: 0;
  }
  .ProductImage {
    border-radius: 50%;
    width: 200px;
    position: relative;
    height: 200px;
  }
  .productDetail {
    width: 100%;
  }

  .quote {
    position: absolute;
    top: 40%;
    left: 37%;
    font-size: 19px;
    width: 400px;
  }

  .Checkout_portion {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .checkoutButton {
    font-size: 2.5vw !important;
    padding: 10px 10px;
    font-family: "Newt Serif Demi Regular";
    cursor: pointer;
  }

  .image2 {
    width: 100px;
  }
  .HeaderLogo {
    width: 120px;
    margin-top: 0;
    margin-right: 0;
    position: absolute;
    right: 23%;
    top: 0%;
  }

  .addToCart {
    font-size: 16px;
  }
  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label {
    margin-right: 10px;
  }
}

@media only screen and (min-width: 901px) and (max-width: 1200px) {
  .productDiv {
    justify-content: space-around;
    flex-direction: column;
    padding: 0;
  }

  .title {
    left: 12%;
    top: 30%;
    font-size: 76px;
    margin-top: -72px;
  }

  .type {
    margin: 0rem 0rem 3rem 4.5rem;
    position: absolute;
    left: 25%;
    top: 32%;
  }
}
