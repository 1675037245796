.modal {
  display: none;
  flex-direction: column;
  background: white;
  border-radius: 18px;
  padding: 14px;
  box-shadow: 2px 1px 21px rgba(0, 0, 0, 0.12);
  overflow-y: auto;
}

.show {
  display: flex;
}

.closeButton {
  align-self: flex-end;
}

@media (min-width: 760px) {
  .modal {
    border-radius: 30px;
    padding: 18px;
  }
}
