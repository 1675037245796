.wrapper{
  padding: 0px 50px;
  width: 50%;
}

.LeftSideTop {
  position: relative;
  width: 50%;
  margin-left: 5%;
}

.productDiv {
  margin-top: 1rem;
  position: relative;
  display: flex;
  width: 80%;
}
 .orderTotal{
  font-family: "Newt Serif Regular";
  font-size: 20px;
  font-weight: 800;
}
.shoppingButton{
  background-color: rgba(27, 65, 65, 0.8);
  cursor: pointer;
  font-size: 1.5vw !important;
  padding: 10px 50px;
  margin: 10px 0;
  border-radius: 5px;
  font-family: "Newt Serif Demi Regular";
}
.shoppingButton2{
  background-color: #ffffff;
  cursor: pointer;
  font-size: 1.5vw !important;
  padding: 10px 20px;
  margin: 10px 0;
  border: 2px solid hsl(175deg 41% 18%);
  border-radius: 5px;
  font-family: "Newt Serif Demi Regular";
}
.shoppingButton:hover{
  background-color: rgba(27, 65, 65, 1);
}
.shoppingButton2:hover{
  background-color: rgba(233, 244, 244, 0.8);
}
.shoppingButton .continueShopping{
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
}
.shoppingButton2 a{
  color: #1a4040;
  text-decoration: none;
  cursor: pointer;
}
.btnWrapper{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  color: hsl(175deg 41% 18%);
  width: 100%;
  font-family: "Newt Serif Regular";
  font-size: 18px;
  font-weight: 400;
}
.btnWrapper2{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  color: hsl(175deg 41% 18%);
  width: 100%;
  font-family: "Newt Serif Regular";
  font-size: 18px;
  font-weight: 400;
}


@media screen and (max-width: 600px) {
  .productDiv {
    width: 100%;
    height: auto !important;
  }
  .ProductImage {
    max-height: 170px !important;
  }
}

@media screen and (max-width: 480px) {
  .ProductImage {
    max-height: 150px !important;
  }
}

.ProductImage {
  border-radius: 50%;
  width: 130px;
  position: relative;
  height: 130px;
}

.productImage {
  width: 30%;
  height: 70%;
}

.img {
  position: absolute;
  right: 0;
  top: 40%;
  height: auto;
  width: 100%;
}

.productDetail {
  width: 65%;
}

.dollar {
  font-size: 20px;
}

.description {
  position: absolute;
  right: 20%;
  /* margin: 3rem 0.8rem;
    display: flex;
    justify-content: flex-end; */
  font-family: "Newt Serif Demi Regular";
}

.description_belowCart {
  margin: 3rem 0rem;
  display: flex;
  justify-content: flex-start;
  font-family: "Newt Serif Demi Regular";
}

.title {
  position: absolute;
  top: 19%;
  font-size: calc(2vh + 2vw - 2vmin);
  font-family: "Newt Serif Demi Regular";
  left: 10%;
  font-weight: bold;
  margin: 0;
  z-index: 222;
  color: rgba(198, 83, 198, 0.664);
}
.type {
  font-size: calc(2vh + 2vw - 2.2vmin);
  position: absolute;
  top: 21%;
  left: 22%;
  font-family: "Newt Serif Demi Regular";
  color: rgba(198, 83, 198, 0.664);
  font-weight: bold;
}
.removeButton {
  position: absolute;
  font-size: 15px;
  font-family: "Newt Serif Demi Regular";
  left: 22%;
  color: #60282e;
  font-weight: bold;
  cursor: pointer;
  margin: 0;
  bottom: 5%;
  z-index: 222;
}

@media screen and (max-width: 1182px) {
  .wrapper{
    width: 70%;
  }
  .title {
    font-size: 45px;
  }
}

.incrementContainer {
  display: flex;
  flex-direction: column;
}

.plusIcon {
  cursor: pointer;
}
.negativeIcon {
  margin-top: 7px;
  cursor: pointer;
}

.incrementContainerOrderTotal {
  display: flex;
  flex-direction: column;
  margin-left: 3%;
}

.smallCircle {
  height: 17px;
  width: 17px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  z-index: -43;
  left: -0.6%;
  top: -2.5%;
}

@media screen and (max-width: 700px) {
  .wrapper{
    padding: 0px 10px;
    width: 86%;
  }
  

  .LeftSideTop {
    position: relative;
    width: 88%;
    margin-left: 5%;
    margin-top: 25%;
  }

  .continueShopping {
    font-size: 14px;
  }
  .incrementContainerOrderTotal {
    margin-left: 0;
  }

  .ProductImage {
    border-radius: 50%;
    width: 100px;
    position: relative;
    height: 100px;
  }

  .title {
    position: absolute;
    top: 17%;
    font-size: calc(2vh + 2vw - 0.2vmin);
    font-family: "Newt Serif Demi Regular";
    left: 10%;
    font-weight: bold;
    margin: 0;
    z-index: 222;
    color: rgba(198, 83, 198, 0.664);
  }
  .type {
    font-size: calc(2vh + 2vw - 0.2vmin);
    position: absolute;
    top: 21%;
    left: 34%;
    font-family: "Newt Serif Demi Regular";
    color: rgba(198, 83, 198, 0.664);
    font-weight: bold;
  }
  .removeButton {
    position: absolute;
    font-size: 16px;
    font-family: "Newt Serif Demi Regular";
    left: 30%;
    color: #60282e;
    font-weight: bold;
    margin: 0;
    bottom: 0;
    z-index: 222;
  }

  .description {
    position: absolute;
    right: 2%;
    /* margin: 3rem 0.8rem;
          display: flex;
          justify-content: flex-end; */
    font-family: "Newt Serif Demi Regular";
  }
}
@media only screen and (max-width: 480px) {
  .btnWrapper{
    flex-direction: column;
  }
.shoppingButton{
  padding: 10px 83px;
}
.shoppingButton2{
  padding: 10px 52px;
}
}
@media (max-width: 1100px) and (min-width: 740px) {
  .LeftSideTop {
    position: relative;
    width: 85%;
    margin-left: 15%;
    margin-top: 20%;
  }

  .continueShopping {
    font-size: 14px;
  }

  .ProductImage {
    border-radius: 50%;
    width: 100px;
    position: relative;
    height: 100px;
  }

  .title {
    position: absolute;
    top: 17%;
    font-size: calc(2vh + 2vw - 0vmin);
    font-family: "Newt Serif Demi Regular";
    left: 10%;
    font-weight: bold;
    margin: 0;
    z-index: 222;
    color: rgba(198, 83, 198, 0.664);
  }
  .type {
    font-size: calc(2vh + 2vw - 0vmin);
    position: absolute;
    top: 21%;
    left: 24%;
    font-family: "Newt Serif Demi Regular";
    color: rgba(198, 83, 198, 0.664);
    font-weight: bold;
  }
  .removeButton {
    position: absolute;
    font-size: 16px;
    font-family: "Newt Serif Demi Regular";
    left: 20%;
    color: #60282e;
    font-weight: bold;
    margin: 0;
    bottom: 0;
    z-index: 222;
  }

  .description {
    position: absolute;
    right: 2%;
    /* margin: 3rem 0.8rem;
  display: flex;
  justify-content: flex-end; */
    font-family: "Newt Serif Demi Regular";
  }
}
