footer {
    text-align: center;
    padding: 20px 0px;
    color: hsl(185deg 13% 63%);
    background: hsl(175deg 41% 18%);
    z-index: 5000;
    border-top: 1px solid hsl(185deg 13% 63%);
}

footer a {
    text-decoration: none;
    padding: 0 10px;
    color: inherit;
}

footer a:hover {
    text-decoration: none;
    padding: 0 10px;
    color: #fff;
}

footer svg {
    fill: currentColor;
}