.contentConstraint {
  width: calc(100% - 40px);
  position: relative;
  height: 100%;
  margin: 0 20px;
}
.heroContainer {
  height: 70vh;
  background-image: url("/img/heroImg.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
}
.heroSubImg {
  position: absolute;
  width: 100%;
  bottom: -10px;
}
.heroHeading {
  font-family: "Newt Serif Demi Regular";
  text-align: right;
  top: 20%;
  right: 20px;
  position: absolute;
  font-size: 1.3rem;
  text-shadow: -1px 1px 2px black;
}
.heroHeading .heading {
  color: #fff;
  font-weight: 100;
  margin: 0;
}
.miniHeading {
  font-weight: 200;
  letter-spacing: 1px;
  color: #fff;
  margin: 0;
  right: 0;
  font-size: 1rem;
}
.subHeading {
  margin: 0;
  color: #4c8b58;
  font-weight: 200;
  font-size: 1.8rem;
  letter-spacing: 1px;
}
.intro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.introHeading {
  padding: 10px 20px;
  text-align: center;
  font-weight: 200;
  color: #4c8b58;
}
.introContainer {
  display: flex;
  position: relative;
}
.introLightImg {
  position: absolute;
  width: 100%;
  top: -150px;
  left: 20px;
  z-index: -10;
}
.introDarkImg {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 180px;
}
.product {
  background-size: cover;
  background-image: url("/img/fernTextureLight.png");
  border-top: 2px solid rgba(76, 139, 88, 0.25);
  padding: 40px 0;
}
.productHeading {
  margin: 0;
  font-size: 3.7rem;
  font-weight: 900;
  color: #496346;
  font-family: "Newt Serif Bold Regular";
}
.productMiniHeading {
  margin: 0 0 40px;
  color: #496346;
  font-weight: 200;
  letter-spacing: 1px;
  font-family: "Newt Serif Regular";
}
.productGrid {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
}
.productContainer {
  width: 33%;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}
.preFooter {
  width: 100%;
  min-height: 400px;
  background-image: url("/img/hempFarm.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 20px;
}
.preFooterContainer {
  display: flex;
  align-content: flex-end;
  min-height: inherit;
}
.preFooterBannerText {
  color: #fff;
  font-family: "Newt Serif Bold Regular";
  align-self: flex-end;
}
.footer {
  background-image: url("/img/fernTextureDark.png");
  background-size: cover;
  padding: 40px 0;
  color: #fff;
}
.footerHeading {
  margin: 0;
  font-family: "Newt Serif Bold Regular";
}
.footerHeadingMini {
  margin: 0;
  font-size: 16px;
}
.footerInnerContent {
  display: flex;
  flex-direction: column-reverse;
}
.footerInnerContent svg {
  fill: #4c8b58;
}
.footerLogo {
  width: 200px;
  margin-top: 32px;
  margin-right: 32px;
  color: #4c8b58;
}
.footerForm {
  margin-top: 36px;
}
.footerFormGroup {
  display: flex;
  margin-bottom: 16px;
}
.footerFormGroup > input {
  width: 40%;
  height: 36px;
  padding-left: 16px;
  border-radius: 8px;
  margin-right: 16px;
  color: #4c8b58;
  border: none;
  font-family: "inknut_antiqualight";
}
.footerFormGroup > input:focus {
  border: none;
  outline: none;
}
.textArea {
  padding: 10px 16px;
  color: #4c8b58;
  border-radius: 8px;
  border: none;
  font-family: "inknut_antiqualight";
  width: calc(100% - 52px);
}

::-webkit-input-placeholder {
  color: #4c8b58;
}
::-moz-placeholder {
  color: #4c8b58;
}
:-ms-input-placeholder {
  color: #4c8b58;
}
:-moz-placeholder {
  color: #4c8b58;
}
.newsletter {
  margin-top: 16px;
  font-family: "Newt Serif Regular";
  display: flex;
  align-items: center;
  max-width: calc(100% - 20px);
}
.newsletter > label {
  margin-left: 8px;
}
.contactButton {
  width: 30px;
  height: 30px;
  min-width: 30px;
  border-radius: 20px;
  background-color: #5b8665;
  color: #fff;
  display: flex;
  margin-left: auto;
  padding-top: 4px;
  align-items: center;
  justify-content: center;
}
.contactButton svg {
  fill: #fff;
}
.displayNote {
  color: white;
}
.hideNote {
  display: none;
}

@media (min-width: 768px) {
  .contentConstraint {
    max-width: 1400px;
    margin: 0 auto;
  }
  .heroContainer {
    height: 90vh;
  }
  .heroSubImg {
    width: 60%;
    top: 20%;
    left: 0;
    max-width: 1000px;
  }
  .heroHeading {
    right: 0;
    top: 40%;
    right: 0;
    font-size: 3rem;
  }
  .miniHeading {
    position: absolute;
    top: 90px;
    font-size: 1.6rem;
  }
  .subHeading {
    font-size: 4.8rem;
  }
  .introHeading {
    padding: 40px 200px 20px;
    max-width: 700px;
  }
  .introLightImg {
    width: 40%;
    top: -100px;
    left: 10%;
  }
  .introDarkImg {
    width: 40%;
    margin-left: auto;
    margin-top: 0px;
    margin-right: 5%;
  }
  .productContainer:last-child {
    margin-right: auto;
  }
  .productHeading {
    font-size: 4.5rem;
  }
  .productGrid {
    flex-direction: row;
  }
  .footerInnerContent {
    flex-direction: row;
    color: #4c8b58;
  }
  .footerFormGroup > input {
    width: 240px;
  }
  .footerForm > textarea {
    width: 500px;
  }
  .newsletter {
    max-width: calc(100% - 20px);
  }
}
