.pageWrap {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
}
.land{
  z-index: -1 !important;
}
.FooterLogo {
  width: 100%;
  height: 300px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.subpage {
  width: 90%;
  flex: 1;
  z-index: 1;
  min-height: calc(100vh - 100px);
}

[data-page="product"] .subpage {
  width: 65%;
  z-index: 1;
}


@media only screen and (min-width: 901px) and (max-width: 1300px) {
  [data-page="product"] .subpage {
    padding: 130px 20px 20px 20px;
    width: 75%;
  }
  .FooterLogo {
    height: 200px;
  }
}

@media only screen and (min-width: 761px) and (max-width: 900px) {
  [data-page="product"] .subpage {
    padding: 130px 20px 20px 20px;
    width: 85%;
  }
  .FooterLogo {
    height: 120px;
  }
}

@media only screen and (max-width: 760px) {
  [data-page="product"] .subpage {
    padding: 20px;
    width: 85%;
  }
  .FooterLogo {
    height: 70px;
  }

}