.cart-count {
  border: 1px solid;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  background-color: rgb(106, 48, 22);
  color: #f8eaff;
  font-family: "Newt Serif Regular";
  z-index: 222;
}
